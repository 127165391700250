import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import GatsbyBackgroundImage from 'gatsby-background-image'

import Services from '../components/Services'
import YoutubeVideo from '../components/YoutubeVideo'
import { HomePageSlider } from '../components/Slider'
import GatsbyImage from 'gatsby-image'

import useHomePageImages from '../hooks/images/useHomePageImages'

const HomeWelcome = () => {
  const { walter, rosi } = useHomePageImages()

  return (
    <section className="mb-4 text-center py-10">
      <div className="container">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-6/12 xl:w-8/12">
            <h1 className="text-4xl">
              Willkommen beim <span className="golden">Stadlwirt</span>
            </h1>
            <hr className="mx-auto my-2" />
            <h2 className="text-2xl">Wir freuen uns über Ihren Besuch!</h2>
            <p>
              Nauders ist ein kleines Bergdorf, inmitten des Dreiländerdreiecks
              Österreich–Schweiz–Italien. Ob Wanderer, Bergsteiger,
              Wintersportler oder Tourengänger, in Nauders sind Sie bestens
              aufgehoben. Genießen Sie Ihren Urlaubsaufenthalt beim Stadlwirt,
              und lassen Sie sich von unseren Schmankerln verwöhnen!
            </p>
            <br />
            <blockquote className="border-l-4 pl-3">
              <p className="pb-2">
                „Beim Kochen geht es ums Bauchgefühl und um die Leidenschaft.
                Wenn das passt, werden Speisen zu Köstlichkeiten!“
              </p>
              <footer>Walter Lutz, der Stadlwirt</footer>
            </blockquote>
          </div>
          <div className="w-1/2 my-5 lg:w-3/12 lg:px-2 lg:my-0 xl:w-2/12">
            <div className="h-64 mx-auto ">
              <GatsbyImage
                fixed={walter.childImageSharp.fixed}
                alt="Rosi Lutz"
                className="h-64 mx-auto"
                imgStyle={{
                  objectFit: 'contain',
                  height: '16rem',
                }}
              />
            </div>
          </div>
          <div className="w-1/2 my-5 lg:w-3/12 lg:px-2 lg:my-0 xl:w-2/12 flex flex-wrap items-center">
            <div className="h-64 mx-auto ">
              <GatsbyImage
                fixed={rosi.childImageSharp.fixed}
                alt="Rosi Lutz"
                className="h-64 mx-auto"
                imgStyle={{
                  objectFit: 'contain',
                  height: '16rem',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const BiggestSchnitzel = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(
          relativePath: { eq: "stadlwirt-riesen-wiener-schnitzel.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <GatsbyBackgroundImage
      Tag="section"
      className="py-20"
      fluid={fluidBackgroundImage.childImageSharp.fluid}
      backgroundColor={`#1a202c`}
    >
      <div className="container flex">
        <div className="md:w-1/3 lg:w-1/2" />
        <div className="w-full bg-gray-200 md:w-2/3 lg:w-1/2 p-5 rounded">
          <h2 className="text-2xl">Das größte Schnitzel Österreichs!</h2>
          <hr className=" my-2" />
          <p>
            Die Idee, das größte Schnitzel Österreichs im Restaurant Stadlwirt
            anzubieten, kam im Jahre 1996 auf. Seither hat das Riesenschnitzel
            großen Anklang gefunden, es war selbst schon Thema in{' '}
            <Link to="/presse" className="golden">
              diversen Presse- und TV-Berichten!
            </Link>
            <br />
            <br />
            Die Gäste des Stadlwirts sind immer wieder aufs Neue begeistert von
            der Größe des bekannten Schnitzels Wiener Art. Das Rezept verrät der
            Gastgeber selbstverständlich nicht, dies soll Betriebsgeheimnis
            bleiben. Aber so viel wird verraten: Das Schnitzel besteht aus einem
            Stück!
          </p>
        </div>
      </div>
    </GatsbyBackgroundImage>
  )
}

const Frivissa = () => {
  return (
    <section className="text-center py-10">
      <div className="container">
        <h2 className="text-2xl">Für meine Schnitzel nur das Beste!</h2>
        <hr className="mx-auto my-2" />
        <p className="max-w-2xl mb-10 mx-auto">
          Damit meine Schnitzel ihren herausragenden Geschmack und Duft
          beibehalten, setzte ich bei bei meinen Küchenprodukten nur auf das
          Beste vom Besten. Mein starker Partner in der Küche heißt{' '}
          <Link to="/frivissa" className="golden">
            Frivissa
          </Link>
          , das beste Speiseöl und Frittierfett fürs Backen, Braten und
          Frittieren!
        </p>
        <YoutubeVideo className="max-w-3xl mx-auto" videoId="V9Su6JBNUZk" />
      </div>
    </section>
  )
}

const HomePage = () => {
  return (
    <>
      <HomePageSlider />
      <HomeWelcome />
      <Services />
      <BiggestSchnitzel />
      <Frivissa />
    </>
  )
}

export default HomePage
