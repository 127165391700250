import React from 'react'

import { LinkButton } from './Button'

import Chef from '../svg/chef.svg'
import Mountains from '../svg/mountains.svg'
import Bed from '../svg/bed.svg'

const RestaurantGuru = () => {
  return (
    <div
      className="mx-auto mt-8"
      style={{
        maxWidth: '140px',
      }}
    >
      <a
        href="https://de.restaurantguru.com/Stadlwirt-Nauders"
        className="rest-white_center "
        target="_blank"
        rel="noreferrer"
      >
        <link
          href="https://awards.infcdn.net/transparent_v2.css"
          rel="stylesheet"
        />
        <div id="rest_white" data={27}>
          <div className="rest-white_year">2022</div>
          <a
            href="https://de.restaurantguru.com/Stadlwirt-Nauders"
            className="rest-white_center "
            target="_blank"
            rel="noreferrer"
          >
            Stadlwirt
          </a>
          <div className="rest_white_arc-heading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="140px"
              height="140px"
              viewBox="0 0 140 140"
            >
              <defs>
                <path
                  id="rest_white_subheading-arc"
                  d="M 12 70 a 58 58 0 0 0 116 0"
                />
              </defs>
              <text
                className="heading__subheading "
                fill="#fff"
                textAnchor="middle"
              >
                <textPath
                  startOffset="50%"
                  xlinkHref="#rest_white_subheading-arc"
                >
                  Restaurant Guru • Empfohlen
                </textPath>
              </text>
            </svg>
          </div>
        </div>
      </a>
    </div>
  )
}

interface Service {
  title: string
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  text: string
  href: string
  hrefLabel: string
  openInNewTab?: boolean
}

const services: Service[] = [
  {
    title: 'Kulinarische Spezialitäten',
    text:
      'Genießen Sie echte Tiroler Gastlichkeit beim Stadlwirt. Wir bieten eine große Auswahl an Speisen von Tiroler Spezialitäten bis zu Rezepten nach Art des Hauses.',
    href: '/restaurant',
    hrefLabel: 'Zum Restaurant',
    Icon: Chef,
  },
  {
    title: 'Atemberaubende Umgebung',
    text:
      'Lassen Sie sich von der grandiosen Natur beeindrucken, die uns hier in Nauders umgibt. Lernen Sie Land und Leute intensiv kennen. Und wo könnte man das besser als bei einer gemütlichen Atmosphäre wie sie der Stadlwirt hat?',
    href: '/nauders',
    hrefLabel: 'Nauders entdecken',
    Icon: Mountains,
  },
  {
    title: 'Unterkünfte',
    text:
      'Ein schöner Urlaub braucht eine gemütliche Unterkunft. Durch unsere vorteilhafte Lage ist man schnell im örtlichen Schigebiet oder auf einem der vielen Wanderwegen. Lassen Sie Tage volle Erlebnisse im Stadlwirt ausklingen.',
    href: '/wohnungen',
    hrefLabel: 'Wohnungen ansehen',
    Icon: Bed,
  },
]

const Services = () => {
  return (
    <section className="text-center bg-gray-900 text-white py-16">
      <div className="container">
        <h2 className="text-2xl">Was wir zu bieten haben</h2>
        <hr className="my-2 mx-auto" />
        <p className="max-w-2xl mx-auto">
          International ist das Restaurant für das sagenumwobene
          Österreichschnitzel bekannt. Doch der Stadlwirt bietet weit mehr als
          diese Spezialität des Hauses, und das nicht nur auf kulinarischer
          Ebene. Entdecken Sie unsere Welt der besonderen Erlebnisse!
        </p>
        <RestaurantGuru />
        <div className="flex flex-wrap pt-10">
          {services.map((service) => {
            const {
              title,
              Icon,
              text,
              href,
              hrefLabel,
              openInNewTab = false,
            } = service

            return (
              <div key={title} className="w-full mb-10 md:w-1/3 md:mb-0">
                <div className="bg-white text-gray-800 p-8 md:px-3 md:mx-2 h-full">
                  {Icon && (
                    <Icon
                      className="rounded mx-auto mb-5"
                      style={{ maxWidth: '9rem' }}
                    />
                  )}

                  <h3 className="text-xl mb-2 font-medium">{title}</h3>
                  <p className="mb-5">{text}</p>
                  <LinkButton to={href} openInNewTab={openInNewTab}>
                    {hrefLabel}
                  </LinkButton>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Services
