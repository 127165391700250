import { useStaticQuery, graphql } from 'gatsby'

import { FixedGatsbyImage } from '../../typings/GatsbyImage'

const useHomePageImages = () => {
  const { walter, rosi } = useStaticQuery(
    graphql`
      query {
        walter: file(relativePath: { eq: "walter-lutz.jpg" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        rosi: file(relativePath: { eq: "rosi-lutz.jpg" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `,
  ) as {
    walter: FixedGatsbyImage
    rosi: FixedGatsbyImage
  }

  return {
    walter,
    rosi,
  }
}

export default useHomePageImages
